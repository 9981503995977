import { useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'

import { api } from '../../../redux/api'
import { ReactComponent as Bolt } from '../../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../../assets/svg/flame.svg'
import { getPaymentStatusIcon, openAttachmentLink } from './utils'

const BillsListBody = ({ items, sortParam }) => {
  const [isFetchingPdfFile, setIsFetchingPdfFile] = useState(false)
  const [trigger] = api.endpoints.getBillPdf.useLazyQuerySubscription()

  return (
    <>
      {!items.length &&
        <tr>
          <td className="no-results" colSpan="9">Nessun risultato trovato.</td>
        </tr>
      }
      {items.map((bill, i) => (
        <tr key={i}>
          <td
            className={classNames({ 'bill-number': true, 'active-col': sortParam.indexOf('number') !== -1 })}
            title={`${bill.type === 2 ? 'Nota di credito' : 'Bolletta'}`}
          >
            {bill.serviceType === 1 ?
              <Bolt className="custom-icon" title="Energia" />
              :
              <Flame className="custom-icon" title="Gas" />
            }
            <span>{bill.number}</span>
          </td>
          <td>{bill.billing.address}, {bill.billing.city}</td>
          <td className={classNames({ 'active-col': sortParam.indexOf('date') !== -1 })}>
            {moment(bill.date).format("DD/MM/YYYY")}
          </td>
          <td>
            {moment(bill.startDate).format("DD/MM/YYYY")}
            <br />
            {moment(bill.endDate).format("DD/MM/YYYY")}
          </td>
          <td className={classNames({ 'amount': true, 'active-col': sortParam.indexOf('amount') !== -1 })}>
            <span
              title={`${bill.localizedPaymentStatus}`}
              className={`bill-status bill-status-${bill.paymentStatus}`}
            >
              <FontAwesomeIcon className="icon-status" icon={['fas', getPaymentStatusIcon(bill.paymentStatus)]}/>
              <span className="total-amount">{bill.amount}€</span>
            </span>
          </td>
          <td className={classNames({ 'active-col': sortParam.indexOf('consumption') !== -1 })}>
            {bill.consumption} {bill.unitOfMeasure}
          </td>
          <td>
            {moment(bill.dueDate).format("DD/MM/YYYY")}
          </td>
          <td className="bill-file">
            <Button
              onClick={async() => {
                setIsFetchingPdfFile(`${bill.normalizedNumber}-1`)
                const response = await trigger({ documentId: bill.normalizedNumber, documentType: 1 })
                setIsFetchingPdfFile(false)
                openAttachmentLink(response.data.file)
              }}
              className="document-link"
              color="link"
              title="Vedi documento"
            >
              {isFetchingPdfFile === `${bill.normalizedNumber}-1` ? (
                <FontAwesomeIcon 
                  icon={['fas', 'spinner-third']} 
                  className="spin-animation"
                />
              ) : (
                <FontAwesomeIcon icon={['fas', 'file-invoice']} />
              )}
            </Button>
          </td>
          <td className="bill-file detail">
            <Button
              onClick={async() => {
                setIsFetchingPdfFile(`${bill.normalizedNumber}-2`)
                const response = await trigger({ documentId: bill.normalizedNumber, documentType: 2 })
                setIsFetchingPdfFile(false)
                openAttachmentLink(response.data.file)
              }}
              className="document-link"
              color="link"
              title="Vedi documento"
            >
              {isFetchingPdfFile === `${bill.normalizedNumber}-2` ? (
                <FontAwesomeIcon 
                  className="spin-animation"
                  icon={['fas', 'spinner-third']} 
                />
              ) : (
                <FontAwesomeIcon icon={['fas', 'file-invoice']} />
              )}
            </Button>
          </td>
        </tr>
      ))}
    </>
  )
}

export default BillsListBody