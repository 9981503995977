import { initializeApp } from 'firebase/app'
import { 
  getAuth, signInWithEmailAndPassword, signOut, 
  onAuthStateChanged, sendPasswordResetEmail
} from "firebase/auth"

import confJson from '../redux/api/configuration.json'

const config = {
  "local": {
    apiKey: "AIzaSyAWA1g0-Xzqm0B33bQ7bl-ss29oRmwpME0",
    authDomain: "abenergie-customerarea-test.firebaseapp.com",
    databaseURL: "https://abenergie-customerarea-test.firebaseio.com",
    projectId: "abenergie-customerarea-test",
    storageBucket: "abenergie-customerarea-test.appspot.com",
    messagingSenderId: "488909927962",
    appId: "1:488909927962:web:51605c82be180ccc31c3a3",
    measurementId: "G-SHR4Y7X7QG"
  },
  "development": {
    apiKey: "AIzaSyBmOm4OV5N_wYaza_cBqgF279GD7Lfc1ac",
    authDomain: "abenergie-customerarea-dev.firebaseapp.com",
    databaseURL: "https://abenergie-customerarea-dev.firebaseio.com",
    projectId: "abenergie-customerarea-dev",
    storageBucket: "abenergie-customerarea-dev.appspot.com",
    messagingSenderId: "379453391928",
    appId: "1:379453391928:web:9fe0c882579a2f8d4012cd"
  },
  "test": {
    apiKey: "AIzaSyAWA1g0-Xzqm0B33bQ7bl-ss29oRmwpME0",
    authDomain: "abenergie-customerarea-test.firebaseapp.com",
    databaseURL: "https://abenergie-customerarea-test.firebaseio.com",
    projectId: "abenergie-customerarea-test",
    storageBucket: "abenergie-customerarea-test.appspot.com",
    messagingSenderId: "488909927962",
    appId: "1:488909927962:web:51605c82be180ccc31c3a3",
    measurementId: "G-SHR4Y7X7QG"
  },
  "staging": {
    apiKey: "AIzaSyCK8BjUTqzq5qApFJ-pZefOySmcKD_G_Vo",
    authDomain: "abenergie-customerarea-staging.firebaseapp.com",
    databaseURL: "https://abenergie-customerarea-staging.firebaseio.com",
    projectId: "abenergie-customerarea-staging",
    storageBucket: "abenergie-customerarea-staging.appspot.com",
    messagingSenderId: "288643014221",
    appId: "1:288643014221:web:4d13689a5db5ebf1dd0604",
    measurementId: "G-MZTHEVTZ26"
  },
  "production": {
    apiKey: "AIzaSyBGV-25Uw3ok5OTmaUreFw2EW26XzjS2Jc",
    authDomain: "abenergie-customerarea.firebaseapp.com",
    databaseURL: "https://abenergie-customerarea.firebaseio.com",
    projectId: "abenergie-customerarea",
    storageBucket: "abenergie-customerarea.appspot.com",
    messagingSenderId: "996033512563",
    appId: "1:996033512563:web:781a95a3c3f8b26ea89606",
    measurementId: "G-0QLC8GSS9C"
  }
}

export const FirebaseErrorMessagesIt = {
  'auth/too-many-requests': "L'accesso a questo account è stato temporaneamente disabilitato a causa di troppi tentativi falliti di login. Puoi ripristinarlo immediatamente reimpostando la password o puoi provare nuovamente più tardi.",
  'auth/wrong-password': "La password inserita non è valida.",
}

const app = initializeApp(config[confJson.env])
export const auth = getAuth(app)

export const signIn = (email, password) => { 
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user
        resolve(user)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const userSignOut = () => signOut(auth)

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, userAuth => {
      unsubscribe()
      resolve(userAuth)
    }, reject)
  })
}

export async function refreshFirebaseToken() {
  const firebaseUser = await getCurrentUser()
  const accessToken = await firebaseUser.getIdToken()
  return accessToken
}

export const resetUserPassword = email => {
  return sendPasswordResetEmail(auth, email)
}