import { createSelector } from "reselect"

const selectAuth = state => state.auth

export const selectCurrentUser = createSelector(
  [selectAuth],
  auth => auth.currentUser
)

export const selectDisplayName = createSelector(
  [selectCurrentUser],
  currentUser => {
    let displayName
    if(currentUser.name && currentUser.surname) {
      displayName = `${currentUser.name} ${currentUser.surname}`
    }

    return displayName || currentUser.email
  }
)

export const selectUtilityModal = createSelector(
  [selectAuth],
  auth => auth.utilityModal
)