import { Alert, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ErrorAlert = ({
  refetchFn,
  color = "warning",
  otherClasses = []
}) => (
  <Alert color={color} className={`${otherClasses.join(" ")}`}>
    Si è verificato un errore
    <Button outline onClick={refetchFn} className="ms-2">
      <FontAwesomeIcon icon={['fas', 'arrows-rotate']} className="me-2" />
      Ricarica
    </Button>
  </Alert>
)

export default ErrorAlert