import styled from 'styled-components'

const CadastralDataPageStyle = styled.div`
  .cadastral-data-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`

export default CadastralDataPageStyle