import { createGlobalStyle } from "styled-components"

const ModalStyles = createGlobalStyle`
  table {
    .active-col {
      background-color: ${({ theme }) => theme.billsThSortableActiveBkg};
    }

    thead {
      color: ${({ theme }) => theme.defaultTextColor};
      font-size: .75rem;
      border-bottom: 2px solid ${({ theme }) => theme.billsThColor};

      th {
        border-top: none;
        border-bottom: none !important;
        vertical-align: middle;
        font-weight: 700;
        font-size: .85rem;
        color: ${({ theme }) => theme.billsThColor};

        > * {
          font-family: inherit;
        }

        &.w-5 {
          width: 5%;
        }

        &.w-10 {
          width: 10%;
        }

        &.w-15 {
          width: 15%;
        }

        &.w-20 {
          width: 20%;
        }

        &.sortable-column {
          cursor: pointer;

          > span {
            margin-right: .25rem;
          }
        }
      }
    }

    tbody {
      color: ${({ theme }) => theme.billsTdColor};
      box-shadow: ${({ theme }) => theme.mainBoxShadow};
      font-size: .7rem;
      overflow: hidden;

      tr:first-child {
        td:first-child {
          border-radius: 10px 0 0 0;
        }
        td:last-child {
          border-radius: 0 10px 0 0;
        }
      }

      tr:last-child {
        td {
          border-bottom: none;
        }

        td:first-child {
          border-radius: 0 0 0 10px;
        }
        td:last-child {
          border-radius: 0 0 10px 0;
        }
      }

      td {
        border: none;
        vertical-align: middle;
        vertical-align: middle;
        color: ${({ theme }) => theme.billsTdColor};
        border-bottom: 1px solid;
        border-color: ${({ theme }) => theme.billsTdBorderColor};
        padding: .75rem 1rem;
        font-weight: 500;

        &.no-results {
          font-weight: 700;
          font-size: .85rem;
          padding: 1.5rem;
          text-align: center;
        }

        &.active-col {
          background-color: ${({ theme }) => theme.billsActiveColBkg};
        }

        &.bill-number {
          .custom-icon {
            margin-right: .75rem;
            vertical-align: middle;
            height: auto;
            width: .75rem;

            @media (max-width: 575.9px) {
              margin-bottom: .25rem;
            }

            path {
              fill: ${({ theme }) => theme.billsThColor};
            }
          }
        }

        &.bill-file {
          text-align: center;
          font-size: 1.25rem;

          button {
            display: inline-block;
            color: ${({ theme }) => theme.billsThColor};
            transition: transform .2s ease;

            &:hover {
              transform: scale(1.1);
            }
          }
          
          .custom-icon {
            width: 1.5rem;
          }
        }

        &.amount {
          .bill-status {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .5rem;
            border-radius: 25px;
            border: none;
            font-size: .75rem;

            .icon-status {
              margin-right: .5rem;
              color: #fff;
              width: 1rem;
            }

            .total-amount {
              color: #fff;
            }

            &.bill-status-1 {
              background-color: #67D167;
            }
            &.bill-status-2 {
              background-color: #EB9D09;
            }
            &.bill-status-3 {
              background-color: #D65050;
            }
          }
        }
      }
    }
  }
`

export default ModalStyles