import styled from "styled-components"

const DefaultPageContainerStyle = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.defaultBkgGradient};
  color: ${({ theme }) => theme.defaultTextColor};

  main {
    padding: 2.5rem 0;
  }
`

export default DefaultPageContainerStyle