import moment from 'moment'
import classNames from 'classnames'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import ErrorAlert from '../ErrorAlert/ErrorAlert.component'
import { ReactComponent as Bolt } from '../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../assets/svg/flame.svg'
import { useGetSelfMeterReadQuery } from '../../redux/customer/customer.api'

const SelfMeterRead = ({ data }) => {
  const { data: selfMeterRead, isLoading, isFetching, isError, refetch } = useGetSelfMeterReadQuery(data.number)

  if(isError) return <ErrorAlert otherClasses={["mt-3"]} refetchFn={refetch} />

  return (
    <div 
      className={
        classNames({
          "self-meter-read": true,
          "is-loading": isLoading || isFetching
        })
      }
    >
      {(isLoading || isFetching) ? (
        <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
          <Skeleton className="skeleton-loader" count={1} height={70} />
          <Skeleton className="skeleton-loader" count={4} height={30} />
        </SkeletonTheme>
      ) : (
        <>
          <header>
              <span className="alias">{data.alias}</span>
              <span className="type-icon">
                {data.supplyType === "pod" ? (
                  <Bolt />
                ) : (
                  <Flame />
                )}
              </span>
          </header>
          <main>
            <div className="supply-point-data">
              <div className="data-group">
                <label>PDR:</label>
                <span className="code">{data.number}</span>
              </div>
              <div className="data-group">
                <label>Indirizzo:</label>
                <span className="code">{data.delivery?.address}</span>
              </div>
            </div>
            <div className="supply-point-data bordered">
              <h3 className="data-title">Ultima autolettura</h3>
              <div className="self-read-data">
                <div className="data-group">
                  <label>Data</label>
                  <span className="code">{moment(selfMeterRead.last?.date).format("DD/MM/YYYY")}</span>
                </div>
                <div className="data-group">
                  <label>Valore</label>
                  <span className="code">{selfMeterRead.last?.value} {selfMeterRead.last?.unitOfMeasure}</span>
                </div>
              </div>
            </div>
            <div className="supply-point-data bordered">
              <h3 className="data-title">Periodo prossima autolettura</h3>
              <div className="self-read-data">
                <div className="data-group">
                  <label>Dal</label>
                  <span className="code">{moment(selfMeterRead.next?.from).format("DD/MM/YYYY")}</span>
                </div>
                <div className="data-group">
                  <label>Al</label>
                  <span className="code">{moment(selfMeterRead.next?.to).format("DD/MM/YYYY")}</span>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </div>
  )
}

export default SelfMeterRead