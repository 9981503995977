import LoginPageStyle from './style'
import DefaultPageContainer from '../DefaultPageContainer/DefaultPageContainer.component'
import LoginForm from '../../components/forms/LoginForm/LoginForm.component'
import { ReactComponent as Logo } from '../../assets/svg/ab-logo.svg'

const LoginPage = () => {
  return (
    <DefaultPageContainer>
      <LoginPageStyle>
        <Logo />
        <LoginForm />
      </LoginPageStyle>
    </DefaultPageContainer>
  )
}

export default LoginPage