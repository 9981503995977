import { configureStore } from "@reduxjs/toolkit"
import logger from 'redux-logger'

import { api } from "./api"
import authReducer from "./auth/auth.reducer"
import customerReducer from "./customer/customer.reducer"
import cadastralDataReducer from "./cadastralData/cadastralData.reducer"
import { rtkQueryErrorLogger } from './middlewares/errorHandler'

let middlewares = [rtkQueryErrorLogger]
if(process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    customer: customerReducer,
    cadastralData: cadastralDataReducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(api.middleware, ...middlewares),
  devTools: process.env.NODE_ENV !== 'production',
})

export default store