import { useState } from "react"
import { Table } from 'reactstrap'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

import CustomerBillsStyle from "./style"
import ErrorAlert from "../ErrorAlert/ErrorAlert.component"
import BillsListHeader from '../BillsList/BillsListHeader/BillsListHeader.component'
import BillsListBody from "../BillsList/BillsListBody/BillsListBody.component"
import Pagination from "../Pagination/Pagination.component"
import { useGetBillsQuery } from "../../redux/customer/customer.api"

const CustomerBills = ({ customerCode }) => {
  const [page, setPage] = useState(1)
  const [sortParam, setSortParam] = useState("-date")
  const { data: bills, isFetching, isLoading, isError, refetch } = useGetBillsQuery({ customerCode, page, sortParam })
  const prevPageAction = () => setPage(page - 1)
  const nextPageAction = () => setPage(page + 1)

  if(isError) return <ErrorAlert otherClasses={["mt-3"]} refetchFn={refetch} />

  return (
    <CustomerBillsStyle>
      <Table responsive="lg">
        <BillsListHeader 
          sortParam={sortParam}
          setSortParam={setSortParam}
        />
        <tbody className="bills-list-body">
          {isLoading || isFetching ? (
            <tr>
              <td className="loading-table-cell" colSpan="100%">
                <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
                  <Skeleton className="skeleton-loader" count={15} height={50} />
                </SkeletonTheme>
              </td>
            </tr>
          ) : (
            <BillsListBody 
              items={bills.items} 
              sortParam={sortParam}
            />
          )}
        </tbody>
      </Table>
      {(!isLoading && !isFetching) &&
        <Pagination 
          selectedPage={page}
          totalPages={bills.pages}
          nextPage={nextPageAction}
          prevPage={prevPageAction}
        />
      }
    </CustomerBillsStyle>
  )
}

export default CustomerBills