import { signInWithEmailAndPassword } from "firebase/auth"

import { auth, getCurrentUser, userSignOut } from "../../firebase"
import { api } from "../api"
import { abLogin, translateFirebaseErrorMessage } from "./auth.utils"

export const authApi = api.injectEndpoints({
  endpoints: build => ({
    login: build.mutation({
      async queryFn(args) {
        try {
          const { user } = await signInWithEmailAndPassword(auth, args.email, args.password)
          const { data: abUser } = await abLogin(user.accessToken)
          const currentUser = {
            accessToken: user.accessToken,
            ...abUser
          }
          return { data: currentUser }
        }
        catch(err) {
          const message = translateFirebaseErrorMessage(err)
          return { error: { message } }
        }
      }
    }),
    checkUserSession: build.query({
      async queryFn(args) {
        const user = await getCurrentUser()
        if(user) {
          const { data: abUser } = await abLogin(user.accessToken)
          const currentUser = {
            accessToken: user.accessToken,
            ...abUser
          }
          return { data: currentUser }  
        }
        return { data: null }
      }
    }),
    logOut: build.mutation({
      async queryFn(args) {
        await userSignOut()
        return { data: null }
      }
    })
  })
})

export const {
  useLoginMutation,
  useCheckUserSessionQuery,
  useLogOutMutation,
} = authApi