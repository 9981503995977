import { useState } from 'react'
import {
  Accordion, AccordionBody, AccordionHeader,
  AccordionItem
} from 'reactstrap'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import CustomerSupplyPointsStyle from './style'
import ErrorAlert from '../ErrorAlert/ErrorAlert.component'
import PodSupplyPointData from './PodsupplyPointData.component'
import PdrSupplyPointData from './PdrSupplyPointData.component'
import { ReactComponent as Bolt } from '../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../assets/svg/flame.svg'
import { useGetSupplyPointsQuery } from '../../redux/customer/customer.api'

const CustomerSupplyPoints = ({ customerCode }) => {
  const [open, setOpen] = useState('-1')
  const toggle = id => open === id ? setOpen('-1') : setOpen(id)
  const { data: supplyPoints, isFetching, isLoading, isError, refetch } = useGetSupplyPointsQuery({ customerCode })

  if(isError) return <ErrorAlert otherClasses={["mt-3"]} refetchFn={refetch} />

  return (
    <CustomerSupplyPointsStyle>
      {(isFetching || isLoading) ? (
        <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
          <Skeleton className="skeleton-loader" count={5} height={70} />
        </SkeletonTheme>
      ) : (
        <Accordion open={open} toggle={toggle}>
          {supplyPoints.map((item, i) => (
            <AccordionItem key={i}>
              <AccordionHeader targetId={`${i}`} className={{ "pdp-closed": item.status === "closed" }}>
                {item.serviceType === "pod" ? (
                  <Bolt className="custom-icon me-2" title="Energia" />
                ) : (
                  <Flame className="custom-icon me-2" title="Gas" />
                )}
                {item.number}
              </AccordionHeader>
              <AccordionBody accordionId={`${i}`}>
                {item.serviceType === "pod" ? (
                  <PodSupplyPointData item={item} />
                ) : (
                  <PdrSupplyPointData item={item} />
                )}
              </AccordionBody>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </CustomerSupplyPointsStyle>
  )
}

export default CustomerSupplyPoints