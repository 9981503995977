import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ButtonWithLoaderStyle from './style'

const ButtonWithLoader = ({ isFetching, buttonLabel = 'Avanti', defaultIcon, otherClasses, ...otherProps }) => (
  <ButtonWithLoaderStyle className={`spinner-btn ${otherClasses || ''}`} {...otherProps}>
    {isFetching ?
      <FontAwesomeIcon icon={['fas', 'spinner-third']} />
    :
      <>
        {defaultIcon &&
          <FontAwesomeIcon icon={defaultIcon} />
        }
      </>
    }
    {buttonLabel}
  </ButtonWithLoaderStyle>
)

export default ButtonWithLoader
