import { api } from "../api"

export const cadastralDataApi = api.injectEndpoints({
  endpoints: build => ({
    getCadastralFormItems: build.query({
      query: () => ({ url: `cadastral-data/items` }),
      transformResponse: (response, meta, arg) => response.data
    }),
    getPostalCodes: build.query({
      query: input => ({ url: `town/${input}/postal-codes` }),
      transformResponse: (response, meta, arg) => response.data.postalCodes
    }),
    getCadastralDataItem: build.query({
      query: itemId => ({ url: `cadastral-data/${itemId}` }),
      transformResponse: (response, meta, arg) => {
        let updatedData = response.data
        updatedData.supplyPoints = []
        const { data: { matchedPod, matchedPdr } } = response
        if(matchedPod.length) {
          updatedData.supplyPoints = [...matchedPod.map(pod => ({ label: pod.number, value: pod.number }))]
        }
        if(matchedPdr.length) {
          updatedData.supplyPoints = [...updatedData.supplyPoints, ...matchedPdr.map(pdr => ({ label: pdr.number, value: pdr.number }))]
        }

        return updatedData
      }
    }),
    createCadastralDataItem: build.mutation({
      query: (values) => ({
        url: `cadastral-data/back-office`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ['CadastralData'],
    }),
    updateCadastralDataItem: build.mutation({
      query: ({ itemId, values }) => ({ 
        url: `cadastral-data/${itemId}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'CadastralData', id }],
    })
  })
})

export const {
  useGetCadastralDataItemQuery,
  useLazyGetCadastralDataItemQuery,
  useGetCadastralFormItemsQuery,
  useLazyGetPostalCodesQuery,
  useCreateCadastralDataItemMutation,
  useUpdateCadastralDataItemMutation,
} = cadastralDataApi

export const {
  endpoints: {
    getPostalCodes,
  }
} = cadastralDataApi