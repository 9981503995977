import { Buffer } from 'buffer'

export const getPaymentStatusIcon = paymentStatus => {
  switch(parseInt(paymentStatus)) {
    case 2:
      return 'history'

    case 3:
      return 'alarm-exclamation'

    default:
      return 'check'
  }
}

export const openAttachmentLink = base64File => {
  const byteCharacters = Buffer.from(base64File, 'base64').toString()
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], {type: 'application/pdf'})
  const blobUrl = URL.createObjectURL(blob)
  window.open(blobUrl)
}