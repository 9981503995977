import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import CadastralDataPageStyle from './style'
import DefaultPageContainer from '../DefaultPageContainer/DefaultPageContainer.component'
import CustomerCodeSearchForm from '../../components/forms/CustomerCodeSearchForm/CustomerCodeSearchForm.component'
import { useLazyGetCustomerCadastralDataQuery } from '../../redux/customer/customer.api'
import { useGetCadastralFormItemsQuery } from '../../redux/cadastralData/cadastralData.api'
import CadastralDataItem from '../../components/CadastralDataItem/CadastralDataItem.component'
import CreateItemModal from '../../components/CadastralDataItem/CreateItemModal/CreateItemModal.component'

const CadastralDataPage = () => {
  const [trigger, { isFetching, isLoading, data: cadastralData }] = useLazyGetCustomerCadastralDataQuery()
  useGetCadastralFormItemsQuery()

  return (
    <DefaultPageContainer>
      <CadastralDataPageStyle>
        <CreateItemModal />
        <CustomerCodeSearchForm 
          trigger={trigger}
          isFetching={isFetching || isLoading} 
          formTitle="Cerca i dati catastali in base al codice cliente"
        />
        {isFetching &&
          <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
            <Skeleton count={5} />
          </SkeletonTheme>
        }
        <div className="cadastral-data-list">
          {
            isFetching === false && 
            cadastralData &&
            cadastralData.map((item, i) => 
              <CadastralDataItem
                data={item} 
                key={i} 
              />
            )            
          }
          {
            isFetching === false &&
            cadastralData && 
            !cadastralData.length &&
            <p className="mt-3 mx-3">Nessun dato catastale associato a questo codice cliente.</p>
          }
        </div>
      </CadastralDataPageStyle>
    </DefaultPageContainer>
  )
}

export default CadastralDataPage