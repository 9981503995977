import styled from "styled-components"

const UserDataStyle = styled.section`
  .data-block {
    color: ${({ theme }) => theme.dataColor};
    margin-bottom: 1.5rem;
    background-color: ${({ theme }) => theme.tabContentBkg};
    box-shadow: ${({ theme }) => theme.dataBoxShadow};
    border-radius: 5px;
    overflow: hidden;

    header {
      padding: .75rem 1rem;
      background-color: ${({ theme }) => theme.dataHeaderBkg};
      color: ${({ theme }) => theme.dataHeaderColor};
      font-weight: 700;
      font-size: 1rem;
    }

    main {
      padding: 1rem;

      .data-label {
        flex-shrink: 1;
        font-weight: 700;

      }

      .data-value {
        width: 100%;
      }
    }
  }
`

export default UserDataStyle