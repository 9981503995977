export const filterTowns = (inputValue, townsArray) => {
  return townsArray.filter((i) =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  )
}

export const filterTownsPromise = (townsArray, inputValue) => {    
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(filterTowns(inputValue, townsArray))
    }, 300)
  })
}