import { useCallback, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { Form, Label, FormGroup, Row, Col } from 'reactstrap'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'

import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import { 
  useCreateCadastralDataItemMutation, 
  useGetCadastralFormItemsQuery, 
  useUpdateCadastralDataItemMutation 
} from "../../../redux/cadastralData/cadastralData.api"
import { getPostalCodes } from "../../../redux/cadastralData/cadastralData.api"
import { filterTownsPromise } from "./utils"
import DependentAsyncSelectField from "../DependentAsyncSelectField/DependentAsyncSelectField.component"

const CadastralDataForm = ({ cadastralDataItem = null, toggle }) => {
  const [supplyPoints, setSupplyPoints] = useState(cadastralDataItem?.supplyPoints || [])
  const [supplyPointsValue, setSupplyPointsValue] = useState(cadastralDataItem?.supplyPoints || [])
  const { register, setValue, watch, handleSubmit, control, formState: { errors, isSubmitting } } = useForm()
  const watchDataLack = watch("dataLack")
  const [trigger] = getPostalCodes.useLazyQuerySubscription()
  const [createCadastralData] = useCreateCadastralDataItemMutation()
  const [updateCadastralData] = useUpdateCadastralDataItemMutation()

  const { formItems, townsArray } = useGetCadastralFormItemsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      formItems: data || [],
      townsArray: data?.cadastralTown || [],
    })
  })

  const townsFilterFn = useCallback(inputValue => { 
    if(inputValue?.length > 2) {
      return filterTownsPromise(townsArray, inputValue) 
    }
  }, [townsArray])

  const onSubmit = async values => {
    if(cadastralDataItem?.id) {
      //Update exsisting
      await updateCadastralData({ itemId: cadastralDataItem.id, values })
      toggle()
    }
    else {
      //Create new
      await createCadastralData(values)
      toggle()
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="grouped-form">
        <Row>
          <Col md={9}>
            <FormGroup>
              <Label for="address">Indirizzo*</Label>
              <input 
                className="form-control"
                type="text" 
                placeholder="Indirizzo"
                {...register("address", { 
                  required: "Campo obbligatorio",
                  value: cadastralDataItem?.address || null
                })}
              />
              {errors.address && <div role="alert" className="invalid-feedback d-block">{errors.address.message}</div>}
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="streetNo">Civico*</Label>
              <input 
                className="form-control"
                type="text" 
                placeholder="Civico"
                {...register("streetNo", { 
                  required: "Campo obbligatorio",
                  value: cadastralDataItem?.streetNo || null
                })}
              />
              {errors.streetNo && <div role="alert" className="invalid-feedback d-block">{errors.streetNo.message}</div>}
            </FormGroup>
          </Col>
          <Col md={9}>
            <FormGroup>
              <Label for="town">Comune*</Label>
              <Controller
                name="town"
                control={control}
                defaultValue={cadastralDataItem?.town || null}
                rules={{ required: "Campo Obbligatorio" }}
                render={({ field: { onChange, ...rest } }) => (
                  <AsyncSelect
                    classNamePrefix="custom-select"
                    isClearable
                    onChange={(e) => {
                      setValue("postalCode", null)
                      onChange(e)
                    }}
                    {...rest}
                    placeholder="Comune"
                    noOptionsMessage={() => 
                      <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                    }
                    loadOptions={townsFilterFn}
                  />
                )}
              />
              {errors.town && <div role="alert" className="invalid-feedback d-block">{errors.town.message}</div>}
            </FormGroup>
          </Col>
          <Col md={3}>
            <DependentAsyncSelectField
              classNamePrefix="custom-select"
              control={control}
              watch={watch}
              watchedFieldName="town"
              triggerFetchFn={trigger}
              fieldName="postalCode"
              fieldLabel="CAP*"
              rules={{ required: "Campo Obbligatorio" }}
              defaultWatchedField={cadastralDataItem?.town || null}
              defaultValue={cadastralDataItem?.postalCode || null}
              errors={errors}
            />
          </Col>
        </Row>
      </div>
      <div className="grouped-form">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="declarantStatus">Qualifica dichiarante*</Label>
              <Controller
                name="declarantStatus"
                control={control}
                rules={{ required: "Campo Obbligatorio" }}
                defaultValue={cadastralDataItem?.declarantStatus || null}
                render={({ field }) => (
                  <ReactSelect
                    classNamePrefix="custom-select"
                    placeholder="Qualifica dichiarante"
                    isClearable
                    {...field}
                    options={formItems?.declarantStatus || []}
                  />
                )}
              />
              {errors.declarantStatus && <div role="alert" className="invalid-feedback d-block">{errors.declarantStatus.message}</div>}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="utilityType">Tipo utenza*</Label>
              <Controller
                name="utilityType"
                rules={{ required: "Campo Obbligatorio" }}
                defaultValue={cadastralDataItem?.utilityType || null}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    classNamePrefix="custom-select"
                    placeholder="Tipo utenza"
                    isClearable
                    {...field}
                    options={formItems?.utilityType || []}
                  />
                )}
              />
              {errors.utilityType && <div role="alert" className="invalid-feedback d-block">{errors.utilityType.message}</div>}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="unitType">Tipo unità*</Label>
              <Controller
                name="unitType"
                rules={{ required: "Campo Obbligatorio" }}
                defaultValue={cadastralDataItem?.unitType || null}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    classNamePrefix="custom-select"
                    placeholder="Tipo unità"
                    isClearable
                    {...field}
                    options={formItems?.unitType || []}
                  />
                )}
              />
              {errors.unitType && <div role="alert" className="invalid-feedback d-block">{errors.unitType.message}</div>}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="cadastralTown">Comune catastale*</Label>
              <Controller
                name="cadastralTown"
                control={control}
                rules={{ required: "Campo Obbligatorio" }}
                defaultValue={cadastralDataItem?.cadastralTown || null}
                render={({ field }) => (
                  <AsyncSelect
                    classNamePrefix="custom-select"
                    isClearable
                    {...field}
                    placeholder="Comune catastale"
                    noOptionsMessage={() => 
                      <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                    }
                    loadOptions={townsFilterFn}
                  />
                )}
              />
              {errors.cadastralTown && <div role="alert" className="invalid-feedback d-block">{errors.cadastralTown.message}</div>}
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="grouped-form">
        <Row>
          <Col md={12}>
            <FormGroup>
              <p className="small">In caso di indisponibilità dei dati catastali identificativi dell’immobile selezionare l’opzione relativa al motivo della mancata comunicazione:</p>
              <Label for="dataLack">Motivo irreperibilità</Label>
              <Controller
                name="dataLack"
                control={control}
                defaultValue={cadastralDataItem?.dataLack || null}
                render={({ field: { onChange, ...rest }}) => (
                  <ReactSelect
                    classNamePrefix="custom-select"
                    isClearable
                    placeholder="Motivo irreperibilità"
                    onChange={e => {
                      setValue("section", null)
                      setValue("certificate", null)
                      setValue("particle", null)
                      setValue("subordinate", null)
                      setValue("particleExtension", null)
                      setValue("particleType", null)
                      onChange(e)
                    }}
                    {...rest}
                    options={formItems?.dataLack || []}
                  />
                )}
              />
            </FormGroup>
          </Col>
          {/* Visibile if dataLack !null */}
          {!watchDataLack?.value &&
            <>
              <Col md={12}>
                <p className="small">Per gli immobili accatastati indicare gli identificativi catastali: Sezione, Foglio, Numero particella, Subalterno</p>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="section">Sezione</Label>
                  <input 
                    className="form-control"
                    type="text" 
                    placeholder="Sezione"
                    {...register("section", {
                      value: cadastralDataItem?.section || null
                    })}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="certificate">Foglio*</Label>
                  <input 
                    className="form-control"
                    type="text" 
                    placeholder="Foglio"
                    {...register("certificate", { 
                      required: "Campo obbligatorio",
                      value: cadastralDataItem?.certificate || null
                    })}
                  />
                  {errors.certificate && <div role="alert" className="invalid-feedback d-block">{errors.certificate.message}</div>}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="particle">Particella/Mappale*</Label>
                  <input 
                    className="form-control"
                    type="text" 
                    placeholder="Particella/Mappale"
                    {...register("particle", { 
                      required: "Campo Obbligatorio",
                      value: cadastralDataItem?.particle || null
                    })}
                  />
                  {errors.particle && <div role="alert" className="invalid-feedback d-block">{errors.particle.message}</div>}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="subordinate">Subalterno</Label>
                  <input 
                    className="form-control"
                    type="text" 
                    placeholder="Subalterno"
                    {...register("subordinate", {
                      value: cadastralDataItem?.subordinate || null
                    })}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <p className="small">Compilare esclusivamente per gli immobili che appartengono al catasto tavolare:</p>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="particleExtension">Estensione particella</Label>
                  <input 
                    className="form-control"
                    type="text" 
                    placeholder="Estensione particella"
                    {...register("particleExtension", {
                      value: cadastralDataItem?.particleExtension || null
                    })}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="particleType">Tipo particella</Label>
                  <Controller
                    name="particleType"
                    control={control}
                    defaultValue={cadastralDataItem?.particleType || null}
                    render={({ field }) => (
                      <ReactSelect
                        classNamePrefix="custom-select"
                        placeholder="Tipo particella"
                        isClearable
                        {...field}
                        options={formItems.particleType || []}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </>
          }
        </Row>
      </div>
      {!cadastralDataItem &&
        <div className="grouped-form">
          <Row>
            <Col md={12}>
              <FormGroup>
                <p className="small">
                  Associa uno o più punti di fornitura a questo dato catastale inserendo il codice POD o PDR qui sotto.
                  <br />
                  <strong>N.B.</strong> Il codice POD deve iniziare con "IT" seguito da 12 caratteri, il codice PDR è composto da 14 numeri.
                </p>
                <Controller
                  name="supplyPoints"
                  control={control}
                  defaultValue={supplyPointsValue}
                  rules={{ required: "Campo Obbligatorio" }}
                  render={({ field }) => (
                    <CreatableSelect 
                      classNamePrefix="custom-select"
                      placeholder="Digita il codice identificativo dell'utenza e premi INVIO per confermare"
                      isMulti 
                      options={supplyPoints} 
                      value={supplyPointsValue}
                      {...field}
                      onCreateOption={value => {
                        if(String(value).length === 14) {
                          const newValue = { label: value, value }
                          setSupplyPoints(prev => [...prev, newValue])
                          setSupplyPointsValue(prev => [...prev, newValue])
                          setValue("supplyPoints", [...supplyPointsValue, newValue])
                        }
                      }}
                      noOptionsMessage={() => 
                        <span className="autocomplete-suggestion">Digita il codice identificativo dell'utenza e premi INVIO per confermare</span>
                      }
                    />
                  )}
                />
                {errors.supplyPoints && <div role="alert" className="invalid-feedback d-block">{errors.supplyPoints.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </div>
      }
      <ButtonWithLoader
        type="submit"
        color="secondary"
        onClick={handleSubmit}
        isFetching={isSubmitting}
        disabled={isSubmitting}
        buttonLabel="Continua"
        className="ab-button ms-auto"
      />
    </Form>
  )
}

export default CadastralDataForm