import { fetchData, getAPIUrl } from "../api/utils"
import { FirebaseErrorMessagesIt, getCurrentUser } from "../../firebase"
import { updateAccessToken } from "./auth.actions"
import store from '../store'

export const AsyncStatusEnum = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export function updateUtilityModal(modalData) {
  return {
    visibility: true,
    modalTitle: "Si è verificato un problema",
    modalIcon: ['fas', 'exclamation-triangle'],
    modalDescription: modalData.message || ""
  }
}

export const translateFirebaseErrorMessage = error => FirebaseErrorMessagesIt[error.code] || error.message

export function abLogin(accessToken) {
  return fetchData(`${getAPIUrl('abStorePortal')}/users/back-office`, accessToken)
}

export async function refreshFirebaseAccessToken(interval = 60000) {
  const firebaseUser = await getCurrentUser()
  return setInterval(() => {
    firebaseUser.getIdToken(true)
      .then(updatedToken => {
        store.dispatch(updateAccessToken(updatedToken))
      })
  }, interval)
}