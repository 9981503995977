import { fetchData, getAPIUrl } from "../api/utils"

export const AsyncStatusEnum = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export function updateUtilityModal(modalData) {
  return {
    visibility: true,
    modalTitle: "Si è verificato un problema",
    modalIcon: ['fas', 'exclamation-triangle'],
    modalDescription: modalData.message || ""
  }
}

export const CustomerTabsMap = {
  CUSTOMER_DATA: 1,
  CUSTOMER_BILLS: 2,
  CUSTOMER_SUPPLY_POINTS: 3,
  CUSTOMER_SELF_METER_READINGS: 4,
  CUSTOMER_CADASTRAL_DATA: 5,
}

export function getCustomerPdps({ pdpType, customerCode, accessToken }) {
  return fetchData(`${getAPIUrl('abStorePortal')}/customers/${customerCode}/${pdpType}`, accessToken)
    .then(response => {
      const updatedData = response.data.map(item => ({ ...item, serviceType: pdpType }))
      return updatedData
    })
}