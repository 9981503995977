import { useState } from 'react'
import { Row, Col, Button } from 'reactstrap'
import moment from 'moment'
import UserDataStyle from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ResetPasswordModal from './ResetPasswordModal/ResetPasswordModal.component'

const UserData = ({ userData }) => {
  const [ resetVisibility, setResetVisibility ] = useState(false)
  const toggleReset = () => setResetVisibility(!resetVisibility)

  return (
    <UserDataStyle>
      <div className="data-block">
        <header>
          Dati cliente
        </header>
        <main>
          <Row>
            <Col xs="3" className="data-label">Cognome</Col>
            <Col className="data-value">{userData?.surname}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Nome</Col>
            <Col className="data-value">{userData?.surname}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Email</Col>
            <Col className="data-value">{userData?.email}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">N. di telefono</Col>
            <Col className="data-value">{userData?.mobileNumber || "-"}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Provider di autenticazione</Col>
            <Col className="data-value">{userData?.provider}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Data di creazione</Col>
            <Col className="data-value">{moment(userData?.insertDate).format("DD/MM/YYYY")}</Col>
          </Row>
          <Row className="mt-3 align-items-center">
            <Col xs="3" className="data-label">L'utente ha dimenticato la password?</Col>
            <Col className="data-value">
              <Button
                onClick={toggleReset}
                color="warning"
              >
                Invia email di recupero
              </Button>
              <ResetPasswordModal 
                visibility={resetVisibility}
                toggle={toggleReset}
                userEmail={userData.email}
              />
            </Col>
          </Row>
        </main>
      </div>
      <div className="data-block">
        <header>
          Codici cliente associati
        </header>
        <main>
          <Row>
            {!userData?.customerCodes.length &&
              <p className="my-2">Nessun codice cliente associato a questo utente.</p>
            }
            {userData?.customerCodes.map((code, i) => 
              <Col className="flex-grow-0" key={i}>
                <Button
                  className="d-flex align-items-center"
                  color="info"
                  onClick={() => window.open(`/customers/${code}`)}
                >
                  <FontAwesomeIcon 
                    className="me-2"
                    icon={['fas', 'arrow-up-right-from-square']} 
                  />
                 <span>{code}</span>
                </Button>
              </Col>
            )}
          </Row>
        </main>
      </div>
    </UserDataStyle>
  )
}

export default UserData