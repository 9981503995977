import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import SelfMeterRead from './SelfMeterRead.component'
import ErrorAlert from '../ErrorAlert/ErrorAlert.component'
import { useGetPdrSupplyPointsQuery } from '../../redux/customer/customer.api'
import CustomerSelfMeterReadsStyle from './style'

const CustomerSelfMeterReads = ({ customerCode }) => {
  const { data, isFetching, isLoading, isError, refetch } = useGetPdrSupplyPointsQuery({ customerCode})
  if(isError) return <ErrorAlert otherClasses={["mt-3"]} refetchFn={refetch} />
  console.log(data)

  return (
    <CustomerSelfMeterReadsStyle>
      {(isFetching || isLoading) ? (
        <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
          <Skeleton className="skeleton-loader" count={5} height={70} />
        </SkeletonTheme>
      ) : (
        <section className="self-meter-read-container">
          {!data.length &&
            <p className="mt-3 mx-3">L'utente non ha forniture GAS attive al momento.</p>
          }
          {data.map((item, i) => 
            <SelfMeterRead data={item} key={i} />
          )}
        </section>
      )}
    </CustomerSelfMeterReadsStyle>
  )
}

export default CustomerSelfMeterReads