import ReactLoading from 'react-loading'

import { WithSpinnerStyle } from './style'
import { ReactComponent as Logo } from '../../assets/svg/ab-logo.svg'

const WithSpinner = WrappedComponent => {
  const Spinner = ({ isLoading, otherClasses, showLogo, ...otherProps }) => {
    return isLoading ? (
      <WithSpinnerStyle 
        className={`${otherClasses?.length ? otherClasses.join(' ') : ''}`}
      >
        {showLogo &&
          <Logo />
        }
        <ReactLoading type="balls" className="loading-icon" />
      </WithSpinnerStyle>
    ) : (
      <WrappedComponent {...otherProps} />
    )
  }

  return Spinner
}

export default WithSpinner
