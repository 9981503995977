import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import NoMatchPage from '../../pages/NoMatchPage/NoMatchPage.component'
import PrivateRoute from './PrivateRoute.component'
import LoginPage from '../../pages/LoginPage/LoginPage.component'
import { selectCurrentUser } from '../../redux/auth/auth.selectors'
import CustomerPage from '../../pages/CustomerPage/CustomerPage.component'
import UserPage from '../../pages/UserPage/UserPage.component'
import CadastralDataPage from '../../pages/CadastralDataPage/CadastralDataPage.component'

const AppRouter = () => {
  const userLoggedIn = useSelector(selectCurrentUser)
  
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              {userLoggedIn ? (
                <Navigate to="/customers" />
              ) : (
                <LoginPage />
              )}
            </>
          }
        />
        
        <Route 
          path="/customers"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <CustomerPage />
            </PrivateRoute>
          }
        >
          <Route 
            path=":customerCode"
            element={
              <PrivateRoute redirectTo="/">
                <CustomerPage />
              </PrivateRoute>
            }
          /> 
        </Route>

        <Route 
          path="/users"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <UserPage />
            </PrivateRoute>
          }
        />

        <Route 
          path="/cadastral-data"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <CadastralDataPage />
            </PrivateRoute>
          }
        />
        
        <Route
          path="*"
          element={<NoMatchPage />} 
        />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter