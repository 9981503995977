import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'

import DefaultPageContainerStyle from './style'
import Header from '../../components/Header/Header.component'
import { selectCurrentUser } from '../../redux/auth/auth.selectors'

const DefaultPageContainer = ({ children }) => {
  const userLoggedIn = useSelector(selectCurrentUser)

  return (
    <DefaultPageContainerStyle>
      {userLoggedIn &&
        <Header />
      }
      <Container>
        <main>
          {children}
        </main>
      </Container>
    </DefaultPageContainerStyle>
  )
}

export default DefaultPageContainer