import GenericStyles from './GenericStyles.style'
import ButtonStyles from './ButtonStyles.style'
import FormStyles from './FormStyles.style'
import ModalStyles from './ModalStyles.style'
import TableStyles from './TableStyles.style'

const GlobalStyles = () => (
  <>
    <GenericStyles />
    <FormStyles />
    <ButtonStyles />
    <ModalStyles />
    <TableStyles />
  </>
)

export default GlobalStyles