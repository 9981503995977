import { createDraftSafeSelector } from "@reduxjs/toolkit"

export const selectCustomer = state => state.customer

export const selectUtilityModal = createDraftSafeSelector(
  [selectCustomer],
  customer => customer.utilityModal
)

export const selectSelectedTab = createDraftSafeSelector(
  [selectCustomer],
  customer => customer.selectedTab
)