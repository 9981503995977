import { get } from "axios"

import confJson from './configuration.json'

//The API url memoized
const getRootUrl = () => {
  let cache = {}
  return (key) => {
    if (key in cache) {
      return cache[key]
    }
    else {
      const { endPoints, env } = confJson
      const { protocol, host, port, prefix, version } = endPoints[env]

      let apiUrl = protocol + '://' + host
      apiUrl = port ? `${apiUrl}:${port}` : apiUrl
      apiUrl = prefix ? `${apiUrl}/${prefix}` : apiUrl
      apiUrl = version ? `${apiUrl}/v${version}` : apiUrl
      cache[key] = apiUrl
      return apiUrl
    }
  }
}

//Istantiate memoized API url
//See API specs on: http://abenergieservices-utils.azurewebsites.net/swagger/index.html
const rootUrl = getRootUrl()
export const getAPIUrl = () => rootUrl()

export const fetchData = (url, accessToken = null, params = null) => {
  let reqHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`
  }

  return get(url, { headers: reqHeaders, params })
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(error.response.data.error?.message || error.response.data)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        throw new Error(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
    })
}

export const mutateData = () => {

}