export const themeVariables = {
  defaultTextColor: "#fff",
  secondaryTextColor: "#26658b",
  mutedTextColor: '#ddd',
  invalidTextColor: "#ffc107",
  defaultBkgGradient: 'linear-gradient(0deg, rgba(38,101,139,1) 0%, rgba(58,153,200,1) 100%)',
  loaderOne: '#0164A7',
  loaderTwo: '#0181d8',
  loaderThree: '#009FF8',
  skeletonBkg: '#eee',
  skeletonBkgImage: 'linear-gradient(90deg, #eee, #f5f5f5, #eee)',

  navBarBkg: '#26658b',
  navBarItemsColor: '#f1f1f1',
  navBarItemsColorHover: '#ddd',
  navBarItemsColorActive: '#ffc107',

  tabContentBkg: '#fff',
  tabColor: '#f1f1f1',
  activeTabColor: '#26658b',
  dataHeaderBkg: '#26658b',
  dataHeaderColor: '#fff',
  dataColor: '#333',
  dataBoxShadow: '0 0 15px -10px #656565',

  billsTableBkg: '#fff',
  billsThColor: '#26658b',
  billsTdColor: '#333',
  billsTdBorderColor: '#26658b',
  billsThSortableActiveBkg: 'rgba(38, 101, 139, .24)',

  supplyPointHeaderColor: '#26658b',
  supplyPointPreviewClosedBkg: "#f6d4c9",

  cadastralItemBkg: '#fff',
  cadastralItemBoxShadow: '0px 0px 16px #00000019;',
  cadastralItemHeaderBkg: '#26658b',
  cadastralItemHeaderColor: '#fff',
  cadastralItemMainColor: '#666',
  pdpItemColor: '#26658b',
}