import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Label, FormGroup, Button, Spinner } from 'reactstrap'

import { useLoginMutation } from '../../../redux/auth/auth.api'

const LoginForm = () => {
  const [login] = useLoginMutation()
  const { register, handleSubmit, watch, setFocus, formState: { errors, isSubmitting } } = useForm()
  const watchEmail = watch("email")
  const watchPassword = watch("password")
  useEffect(() => {
    setFocus("email")
  }, [setFocus])

  const onSubmit = values => login(values)

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for="email">Email</Label>
        <input 
          className="form-control"
          type="text" 
          {...register("email", { 
            required: true,
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Email non valida"
            },
            value: ""
          })}
        />
        {errors.email && <div role="alert" className="invalid-feedback d-block">{errors.email?.message}</div>}
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <input 
          className="form-control"
          type="password"
          {...register("password", { required: "Campo obbligatorio", value: "" })}
        />
        {errors.password && <div className="invalid-feedback d-block">{errors.password?.message}</div>}
      </FormGroup>
      <FormGroup className="text-end">
        <Button 
          type="submit"
          color="warning"
          disabled={
            !watchEmail ||
            !watchPassword ||
            isSubmitting
          }
        >
          {isSubmitting &&
            <Spinner size="sm" className="me-1" />
          }
          Log in
        </Button>
      </FormGroup>
    </Form>
  )
}

export default LoginForm