import React from 'react'
import { useLocation } from 'react-router'

import NoMatchPageStyle from './style'
import DefaultPageContainer from '../DefaultPageContainer/DefaultPageContainer.component'

const NoMatchPage = () => {
  const { pathname } = useLocation()

  return (
    <DefaultPageContainer>
      <NoMatchPageStyle>
        <h3 className="title">
          La pagina <code>{pathname}</code> non esiste!{' '}
        </h3>
      </NoMatchPageStyle>
    </DefaultPageContainer>
  )
}

export default NoMatchPage
