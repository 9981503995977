import styled from 'styled-components'

const NoMatchPageStyle = styled.div`
  .title {
    .emoji {
      font-size: 5rem;
      margin-left: 1rem;
    }
  }
`

export default NoMatchPageStyle