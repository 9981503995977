import { useSelector, useDispatch } from 'react-redux'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import CustomerTabsStyle from './style'
import { customerChangeSelectedTab } from '../../redux/customer/customer.actions'
import { selectSelectedTab } from '../../redux/customer/customer.selectors'
import { CustomerTabsMap } from '../../redux/customer/customer.utils'

const CustomerTabs = () => {
  const dispatch = useDispatch()
  const selectedTab = useSelector(selectSelectedTab)

  return (
    <CustomerTabsStyle>
      <Nav tabs className="subscription-nav">
        <NavItem>
          <NavLink
            onClick={e => dispatch(customerChangeSelectedTab(CustomerTabsMap.CUSTOMER_DATA))}
            className={classNames({ "active": selectedTab === CustomerTabsMap.CUSTOMER_DATA })}
          >
            <FontAwesomeIcon icon="address-card" className="me-2" />
            Dati cliente
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={e => dispatch(customerChangeSelectedTab(CustomerTabsMap.CUSTOMER_BILLS))}
            className={classNames({ "active": selectedTab === CustomerTabsMap.CUSTOMER_BILLS })}
          >
            <FontAwesomeIcon icon="money-bill-wave" className="me-2" />
            Bollette
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={e => dispatch(customerChangeSelectedTab(CustomerTabsMap.CUSTOMER_SUPPLY_POINTS))}
            className={classNames({ "active": selectedTab === CustomerTabsMap.CUSTOMER_SUPPLY_POINTS })}
          >
            <FontAwesomeIcon icon="home" className="me-2" />
            Utenze
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={e => dispatch(customerChangeSelectedTab(CustomerTabsMap.CUSTOMER_SELF_METER_READINGS))}
            className={classNames({ "active": selectedTab === CustomerTabsMap.CUSTOMER_SELF_METER_READINGS })}
          >
            <FontAwesomeIcon icon="watch-calculator" className="me-2" />
            Autoletture
          </NavLink>
        </NavItem>
      </Nav>
    </CustomerTabsStyle>
  )
}

export default CustomerTabs