import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import UserPageStyle from './style'
import DefaultPageContainer from '../DefaultPageContainer/DefaultPageContainer.component'
import UserSearchForm from '../../components/forms/UserSearchForm/UserSearchForm.component'
import UserData from '../../components/UserData/UserData.component'
import { useLazyGetUserQuery } from '../../redux/user/user.api'

const UserPage = () => {
  const [trigger, { isFetching, data: userData }] = useLazyGetUserQuery()

  return (
    <DefaultPageContainer>
      <UserPageStyle>
        <UserSearchForm 
          trigger={trigger}
          isFetching={isFetching} 
        />
        {isFetching &&
          <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
            <Skeleton count={5} />
          </SkeletonTheme>
        }
        {
          isFetching === false && 
          userData &&
            <UserData userData={userData} />
        }
      </UserPageStyle>
    </DefaultPageContainer>
  )
}

export default UserPage