import styled from "styled-components"

const PdpItemStyle = styled.div`
  display: flex;
  padding: .75rem;
  fill: ${({ theme }) => theme.pdpItemColor};
  color: ${({ theme }) => theme.pdpItemColor};

  .data-group {
    align-self: center;
    flex-basis: 50%;
    flex-grow: 1;
    padding: 0;

    .code {
      font-weight: 700;
      font-size: .9rem;
      display: block;
    }
  }

  .pdp-icon {
    font-size: 1.5rem;
    margin-right: 1rem;
    align-self: center;
    flex-basis: 0;
    flex-grow: 0;
  }

  .action-btns {
    flex-basis: 0;
    flex-grow: 0;
  }  
`

export default PdpItemStyle