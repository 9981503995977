import { useState, useEffect, useCallback } from "react"
import { Controller } from "react-hook-form"
import ReactSelect from 'react-select'
import { FormGroup, Label } from "reactstrap"

const DependentAsyncSelectField = ({
  watch,
  watchedFieldName,
  triggerFetchFn,
  errors,
  fieldName,
  fieldLabel,
  defaultWatchedField,
  ...otherProps
}) => {
  const [options, setOptions] = useState([])

  const updateOptions = useCallback(async(data, fieldProps) => {
    const response = await triggerFetchFn(fieldProps?.name ? data[fieldProps.name].value : data)
    if(Array.isArray(response.data)) {
      setOptions(response.data.map(value => ({ label: value, value })))
    }
    else {
      setOptions(response.data)
    }
  }, [triggerFetchFn])
  
  useEffect(() => {
    if(defaultWatchedField?.value) {
      updateOptions(defaultWatchedField.value)
    }

    const subscription = watch((data, { name, type }) => {
      if(name === watchedFieldName && data[name]?.value) {
        updateOptions(data, { name, type })
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, watchedFieldName, defaultWatchedField, updateOptions])
  
  return (
    <FormGroup>
      <Label for={fieldName}>{fieldLabel}</Label>
        <Controller
          name={fieldName}
          render={({ field }) => (
            <ReactSelect
              isClearable
              {...field}
              options={options}
              classNamePrefix={otherProps.classNamePrefix || null}
            />
          )}
          {...otherProps}
        />
        {errors[fieldName] && <div role="alert" className="invalid-feedback d-block">{errors[fieldName].message}</div>}
    </FormGroup>
  )
}

export default DependentAsyncSelectField