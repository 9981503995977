import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CadastralDataForm from '../../forms/CadastralDataForm/CadastralDataForm.component'
import { toggleCadastralDataFormModal } from '../../../redux/cadastralData/cadastralData.actions'
import { selectCadastralDataFormVisibility } from '../../../redux/cadastralData/cadastralData.selectors'

const UpdateItemModal = ({ itemData }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectCadastralDataFormVisibility)
  const toggle = () => dispatch(toggleCadastralDataFormModal())

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop="static" keyboard={false}>
      <ModalHeader 
        toggle={toggle} 
        close={
          <Button
            onClick={toggle}
            color="transparent"
            className="custom-close-btn"
          >
            <FontAwesomeIcon icon={['fas', 'xmark']} />
          </Button>
        }
      >
        <FontAwesomeIcon icon={['fas', 'map-location-dot']} className="custom-icon" />
        Aggiorna dato catastale
      </ModalHeader>
      <ModalBody>
        <CadastralDataForm 
          cadastralDataItem={itemData} 
          toggle={toggle}
        />
      </ModalBody>
    </Modal>
  )
}

export default UpdateItemModal