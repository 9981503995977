import { createGlobalStyle } from "styled-components"

const FormStyles = createGlobalStyle`
  form {
    .grouped-form {
      padding: 0.75rem;
      border-radius: 10px;
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
      background-color: transparent;
      margin-top: 1rem;
      margin-bottom: 2rem;

      label {
        color: ${({ theme }) => theme.secondaryTextColor};
      }
    }    

    label {
      font-weight: 500;
      font-size: .9rem;
      color: ${({ theme }) => theme.defaultTextColor};
      margin-bottom: .1rem !important;
    }

    .form-control {
      font-size: .8rem;
      padding: .5rem .75rem;

      &::placeholder {
        font-size: .8rem;
      }
    }

    .invalid-feedback {
      font-size: .8rem;
    }

    .form-text {
      color: ${({ theme }) => theme.mutedTextColor} !important;
      font-size: .8rem;
    }
  }

  .custom-select__placeholder {
    font-size: .8rem;
  }

  .custom-select__menu,
  .custom-select__option,
  .custom-select__input,
  .custom-select__single-value {
    font-size: .8rem !important;
  }
`

export default FormStyles