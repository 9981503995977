import styled from "styled-components"

const CustomerSupplyPointsStyle = styled.section`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.tabContentBkg};
  border-radius: 0 0 5px 5px;

  .custom-icon {
    width: .75rem;
  }

  .accordion-header {
    &.pdp-closed button {
      background-color: ${({ theme }) => theme.supplyPointPreviewClosedBkg };
    }

    .accordion-button {
      fill: ${({ theme }) => theme.supplyPointHeaderColor};
      color: ${({ theme }) => theme.supplyPointHeaderColor};
    }
  }

  .accordion-body {
    padding: 1rem;

    .data-label {
      flex-shrink: 1;
      font-weight: 700;

    }

    .data-value {
      width: 100%;
    }
  }
`

export default CustomerSupplyPointsStyle