import { createReducer } from "@reduxjs/toolkit"

import { toggleCadastralDataFormModal } from './cadastralData.actions'
import { cadastralDataApi } from "./cadastralData.api"

const INITIAL_STATE = {
  cadastralDataFormVisibility: false,
  error: null,
}

const cadastralDataReducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(toggleCadastralDataFormModal, (state, action) => {
      state.cadastralDataFormVisibility = !state.cadastralDataFormVisibility
    })
    .addMatcher(cadastralDataApi.endpoints.getCadastralDataItem.matchFulfilled, (state, action) => {
      state.cadastralDataFormVisibility = true
    })
})

export default cadastralDataReducer