import { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"

import { resetUserPassword } from "../../../firebase"

const ResetPasswordModal = ({ visibility, toggle, userEmail }) => {
  const [isLoading, setIsLoading] = useState(false)
  const sendResetEmail = async() => {
    setIsLoading(true)
    await resetUserPassword(userEmail)
    setIsLoading(false)
    toggle()
  }

  return (
    <Modal isOpen={visibility} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Reset password
      </ModalHeader>
      <ModalBody>
        Confermando verrà inviata un'email contenente il link necessario 
        al reset della password per l'utente <strong>{userEmail}</strong>.
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isLoading}
          onClick={sendResetEmail}
        >
          {isLoading &&
            <Spinner className="me-2" size="sm" />
          }
          Conferma
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ResetPasswordModal