import PdpItemStyle from './style'
import { ReactComponent as Bolt } from '../../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../../assets/svg/flame.svg'

const PdpItem = ({ alias, supplyType }) => {
  return (
    <PdpItemStyle>
      <div className="data-group pdp-icon">
        {supplyType.label === "Energia" ? (
          <Bolt title ="Punti di fornitura Energia" />
        ) : (
          <Flame title="Punto di fornitura Gas" />
        )}
      </div>
      <div className="data-group alias">
        <span className="code">
          {alias}
        </span>
      </div>
    </PdpItemStyle>
  )
}

export default PdpItem