import styled from "styled-components"

const HeaderStyle = styled.header`
   background-color: #26658b;
   padding: .25rem 0;

  .logo svg {
    width: 160px;
  }

  .navbar-collapse {
    margin: 0 1rem;
  }

  .nav-item {
    padding: 0 .25rem;
  }

  .nav-link {
    color: ${({ theme }) => theme.navBarItemsColor};
    font-size: 1rem;
    font-weight: 500;
    transition: all .2s ease;

    &:hover,
    &:active {
      transform: scale(.98);
      color: ${({ theme }) => theme.navBarItemsColorHover};
    }

    &.active {
      border: 1px solid #ffc107;
      border-radius: 10px;
      color: #ddd;
    }

    &.user-logout {
      cursor: pointer;
      color: ${({ theme }) => theme.navBarItemsColor};
    }
  }
`

export default HeaderStyle