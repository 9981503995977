import { createGlobalStyle } from "styled-components"

const ModalStyles = createGlobalStyle`
  .modal {
    .modal-header {
      border-bottom: none;
      color: ${({ theme }) => theme.defaultTextColor};
      background-color: ${({ theme }) => theme.navBarBkg};

      svg {
        margin-right: .5rem;
      }

      .custom-close-btn,
      .btn-close {
        color: ${({ theme }) => theme.defaultTextColor};
        transition: transform .3s ease;
        font-size: 1.25rem;
        line-height: 1;

        svg {
          margin-right: 0;
        }

        &:active,
        &:hover {
          transform: scale(.9);
        }

        &:focus {
          border-color: transparent;
        }
      }
    }
    
    .modal-body {
      p {
        color: ${({ theme }) => theme.dataColor};
      }
    }
  }
`

export default ModalStyles