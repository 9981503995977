import { useState, useEffect, useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

const SortableTh = ({ 
  className, 
  sortParam, 
  setSortParam, 
  colParam, 
  colLabel 
}) => {
  const [ icon, setIcon ] = useState('sort')

  const handleSorting = useCallback(() => {
    const newParam = sortParam === colParam ? `-${colParam}` : colParam
    setSortParam(newParam)
  }, [colParam, sortParam, setSortParam])

  useEffect(() => {
    if(sortParam.indexOf(colParam) === -1) setIcon('sort')
    if(sortParam.indexOf(colParam) !== -1 && sortParam.indexOf("-") === -1) setIcon('sort-up')
    if(sortParam.indexOf(colParam) !== -1 && sortParam.indexOf("-") !== -1) setIcon('sort-down')
  }, [sortParam, colParam])

  return (
    <th
      className={
        classNames({
          'sortable-column': true,
          [className]: className !== undefined,
          'active-col': sortParam.indexOf(colParam) !== -1
        })
      }
      onClick={() => handleSorting(colParam)}
    >
      <span>{colLabel}</span>
      <FontAwesomeIcon icon={['fas', icon]} />
    </th>
  )
}

export default SortableTh