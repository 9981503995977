import { useForm } from 'react-hook-form'
import { Form, FormGroup, Label, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import UserSearchFormStyle from './style'
import { emailCheck } from '../../../utils/validations'

const UserSearchForm = ({ trigger, isFetching }) => {
  const { register, handleSubmit, watch } = useForm()
  const watchUserName = watch("userName")

  const onSubmit = values => trigger(values.userName, true)

  return (
    <UserSearchFormStyle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>Inserisci l'email dell'utente per visualizzare i suoi dati</Label>
          <input 
            type="text"
            placeholder="Ad esempio: user@abenergie.it"
            className="form-control"
            {...register("userName", { required: true })}
          />
        </FormGroup>
        <FormGroup className="text-end">
          <Button
            disabled={
              !watchUserName ||
              emailCheck(watchUserName) === false
            }
          >
            {isFetching ? (
              <Spinner size="sm" className="me-2" />
            ) : (
              <FontAwesomeIcon icon="magnifying-glass" className="me-2" />
            )}
            Cerca
          </Button>
        </FormGroup>
      </Form>
    </UserSearchFormStyle>
  )
}

export default UserSearchForm