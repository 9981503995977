import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PaginationStyle from './style'

const Pagination = ({ selectedPage, totalPages, prevPage, nextPage }) => {
  return (
    <PaginationStyle>
      <Button
        disabled={selectedPage === 1}
        onClick={prevPage}
      >
        <FontAwesomeIcon icon={['fas', 'arrow-left']} className="me-2" />
        Precedente
      </Button>
      <Button
        disabled={selectedPage === totalPages}
        onClick={nextPage}
      >
        Successivo
        <FontAwesomeIcon icon={['fas', 'arrow-right']} className="ms-2" />
      </Button>
    </PaginationStyle>
  )
}

export default Pagination