import { createReducer } from "@reduxjs/toolkit"

import { AsyncStatusEnum } from "./auth.utils"
import { authApi } from "./auth.api"
import { toggleUtilityModalVisibility, updateAccessToken } from "./auth.actions"

const INITIAL_STATE = {
  fetchStatus: [null, AsyncStatusEnum.IDLE],
  submitStatus: [null, AsyncStatusEnum.IDLE],
  currentUser: null,
  error: null,
  utilityModal: {
    visibility: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const authReducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(toggleUtilityModalVisibility, (state, action) => {
      state.utilityModal.visibility = !state.utilityModal.visibility
    })
    .addCase(updateAccessToken, (state, action) => {
      state.currentUser.accessToken = action.payload
    })
    .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
      state.submitStatus = ['userLogin', AsyncStatusEnum.LOADING]
    })
    .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      state.currentUser = action.payload
      state.submitStatus = ['userLogin', AsyncStatusEnum.SUCCESS]
    })
    .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
      state.submitStatus = ['userLogin', AsyncStatusEnum.FAILURE]
    })
    .addMatcher(authApi.endpoints.checkUserSession.matchPending, (state, action) => {
      state.fetchStatus = ['userSession', AsyncStatusEnum.LOADING]
    })
    .addMatcher(authApi.endpoints.checkUserSession.matchFulfilled, (state, action) => {
      state.fetchStatus = ['userSession', AsyncStatusEnum.SUCCESS]
      if(action.payload) {
        state.currentUser = action.payload
      }
    })
    .addMatcher(authApi.endpoints.checkUserSession.matchRejected, (state, action) => {
      if(action.payload) {
        state.submitStatus = ['userLogin', AsyncStatusEnum.FAILURE]
      }
    })
    .addMatcher(authApi.endpoints.logOut.matchFulfilled, (state, action) => {
      return {
        ...INITIAL_STATE,
        submitStatus: ['userLogout', AsyncStatusEnum.SUCCESS]
      }
    })
})

export default authReducer