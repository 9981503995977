import { Row, Col } from 'reactstrap'

import CustomerDataStyle from "./style"

const CustomerData = ({ data: { domicile, contactPerson, ...data } }) => {
  return (
    <CustomerDataStyle className="data-tab-container">
      <div className="data-block">
        <header>
          Dati cliente
        </header>
        <main>
          <Row>
            <Col xs="3" className="data-label">Codice</Col>
            <Col className="data-value">{data.code}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Anagrafica</Col>
            <Col className="data-value">{data.fullName}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Codice fiscale</Col>
            <Col className="data-value">{data.fiscalCode}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">P. IVA</Col>
            <Col className="data-value">{data.vatNumber}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Email</Col>
            <Col className="data-value">{data.email}</Col>
          </Row>
        </main>
      </div>
      <div className="data-block">
        <header>
          Indirizzo domicilio
        </header>
        <main>
          <Row>
            <Col xs="3" className="data-label">Indirizzo</Col>
            <Col className="data-value">{domicile.address}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">CAP</Col>
            <Col className="data-value">{domicile.postalCode}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Città</Col>
            <Col className="data-value">{domicile.city}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Provincia</Col>
            <Col className="data-value">{domicile.province}</Col>
          </Row>
        </main>
      </div>
      <div className="data-block">
        <header>
          Referente dedicato
        </header>
        <main>
          <Row>
            <Col xs="3" className="data-label">Anagrafica</Col>
            <Col className="data-value">{contactPerson.name}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Codice</Col>
            <Col className="data-value">{contactPerson.code}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Telefono</Col>
            <Col className="data-value">{contactPerson.phoneNumber}</Col>
          </Row>
          <Row>
            <Col xs="3" className="data-label">Email</Col>
            <Col className="data-value">{contactPerson.email}</Col>
          </Row>
        </main>
      </div>
    </CustomerDataStyle>
  )
}

export default CustomerData