import styled from "styled-components"

const CustomerTabsStyle = styled.div`
  .nav-link {
    color: ${({ theme }) => theme.tabColor};
    cursor: pointer;
    font-weight: 500;

    &.active {
      color: ${({ theme }) => theme.activeTabColor};
    }
  }
`

export default CustomerTabsStyle