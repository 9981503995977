import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CadastralDataItemStyle from './style'
import PdpItem from './PdpItem/PdpItem.component'
import ButtonWithLoader from '../ButtonWithLoader/ButtonWithLoader.component'
import { useLazyGetCadastralDataItemQuery } from '../../redux/cadastralData/cadastralData.api'
import UpdateItemModal from './UpdateItemModal/UpdateItemModal.component'

const CadastralDataItem = ({ data }) => {  
  const [fetchItemData, { isLoading, isFetching, data: cadastralDataItem }, { lastArg }] = useLazyGetCadastralDataItemQuery()

  return (
    <CadastralDataItemStyle>
      <header>
        <FontAwesomeIcon icon={['fas', 'map-location-dot']} className="custom-icon" />
        <h1 className="alias">
          {data.address} {data.streetNo} {data.postalCode?.label} - {data.town?.label}
        </h1>
      </header>
      <main>
        <section>
          <h3 className="supply-points-title">Utenze associate:</h3>
          {data.matchedPod.length || data.matchedPdr.length ? (
            <div className="pdp-container">
              {data.matchedPod.map(item => (
                <PdpItem
                  key={item.id}
                  cadastralDataItemId={data.id}
                  {...item}
                />
              ))}
              {data.matchedPdr.map(item => (
                <PdpItem
                  key={item.id}
                  cadastralDataItemId={data.id}
                  {...item}
                />
              ))}
            </div>
          ) : (
            <div className="supply-point-data">Nessuna utenza associata</div>
          )}
        </section>
      </main>
      <footer>
        <ButtonWithLoader
          className="ab-button"
          defaultIcon={['fas', 'edit']}
          title="Modifica questi dati catastali"
          buttonLabel="Modifica"
          isFetching={isLoading || isFetching}
          onClick={() => fetchItemData(data.id)}
        />
      </footer>
      {
        lastArg === data.id &&
        isFetching === false &&
        isLoading === false &&
        cadastralDataItem &&
        <UpdateItemModal 
          itemData={cadastralDataItem}
        />
      }
    </CadastralDataItemStyle>
  )
}

export default CadastralDataItem