import { useState } from "react"
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import CadastralDataForm from "../../forms/CadastralDataForm/CadastralDataForm.component"

const CreateItemModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <section className="d-flex justify-content-end mb-4">
      <Button
        color="warning"
        onClick={toggle}
      >
        Aggiungi dato catastale
        <FontAwesomeIcon icon={['fas', 'plus-circle']} className="ms-2" />
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop="static" keyboard={false}>
        <ModalHeader 
          toggle={toggle} 
          close={
            <Button
              onClick={toggle}
              color="transparent"
              className="custom-close-btn"
            >
              <FontAwesomeIcon icon={['fas', 'xmark']} />
            </Button>
          }
        >
          <FontAwesomeIcon icon={['fas', 'map-location-dot']} className="custom-icon" />
          Nuovo dato catastale
        </ModalHeader>
        <ModalBody>
          <CadastralDataForm toggle={toggle}/>
        </ModalBody>
      </Modal>
    </section>
  )
}

export default CreateItemModal