import { Row, Col } from "reactstrap"
import moment from "moment"

const PodSupplyPointData = ({ item }) => (
  <>
    <Row>
      <Col xs="3" className="data-label">Codice pod</Col>
      <Col className="data-value">{item.number}</Col>
    </Row>
    <Row>
      <Col xs="3" className="data-label">Offerta</Col>
      <Col className="data-value">{item.offerName}</Col>
    </Row>
    <Row>
      <Col xs="3" className="data-label">Penso In Verde</Col>
      <Col className="data-value">{item.greenOption ? "Sì" : "No"}</Col>
    </Row>
    <Row>
      <Col xs="3" className="data-label">Stato attivazione</Col>
      <Col className="data-value">{item.status === "closed" ? "Chiuso" : "Attivo"}</Col>
    </Row>
    <Row>
      <Col xs="3" className="data-label">Data attivazione</Col>
      <Col className="data-value">{moment(item.activationDate).format("DD/MM/YYYY")}</Col>
    </Row>
    <Row>
      <Col xs="3" className="data-label">Indirizzo</Col>
      <Col className="data-value">{item.delivery.address} {item.delivery.postalCode}</Col>
    </Row>
    <Row>
      <Col xs="3" className="data-label">Città</Col>
      <Col className="data-value">{item.delivery.city} ({item.delivery.province})</Col>
    </Row>
  </>
)

export default PodSupplyPointData