import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

import CustomerPageStyle from "./style"
import DefaultPageContainer from "../DefaultPageContainer/DefaultPageContainer.component"
import CustomerCodeSearchForm from "../../components/forms/CustomerCodeSearchForm/CustomerCodeSearchForm.component"
import CustomerTabs from '../../components/CustomerTabs/CustomerTabs.component'
import CustomerData from '../../components/CustomerData/CustomerData.component'
import CustomerBills from "../../components/CustomerBills/CustomerBills.component"
import CustomerSupplyPoints from "../../components/CustomerSupplyPoints/CustomerSupplyPoints.component"
import { selectSelectedTab } from "../../redux/customer/customer.selectors"
import { useLazyGetCustomerQuery } from "../../redux/customer/customer.api"
import { CustomerTabsMap } from "../../redux/customer/customer.utils"
import CustomerSelfMeterReads from "../../components/CustomerSelfMeterReads/CustomerSelfMeterReads.component"
import { resetCustomerTab } from "../../redux/customer/customer.actions"

const CustomerPage = () => {
  const dispatch = useDispatch()
  const [trigger, { isFetching, data: customerData }] = useLazyGetCustomerQuery()
  const selectedTab = useSelector(selectSelectedTab)

  useEffect(() => {
    return () => dispatch(resetCustomerTab())
  }, [dispatch])

  return (
    <DefaultPageContainer>
      <CustomerPageStyle>
        <CustomerCodeSearchForm 
          trigger={trigger}
          isFetchingData={isFetching}
        />
        {isFetching &&
          <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
            <Skeleton count={5} />
          </SkeletonTheme>
        }
        {/* Customer details */}
        {
          isFetching === false && 
          customerData &&
            <>
              <CustomerTabs />
              {selectedTab === CustomerTabsMap.CUSTOMER_DATA &&
                <CustomerData data={customerData} />
              }
              {selectedTab === CustomerTabsMap.CUSTOMER_BILLS &&
                <CustomerBills customerCode={customerData.code} />
              }
              {selectedTab === CustomerTabsMap.CUSTOMER_SUPPLY_POINTS &&
                <CustomerSupplyPoints customerCode={customerData.code} />
              }
              {selectedTab === CustomerTabsMap.CUSTOMER_SELF_METER_READINGS &&
                <CustomerSelfMeterReads customerCode={customerData.code} />
              }
            </>
        }
      </CustomerPageStyle>
    </DefaultPageContainer>
  )
}

export default CustomerPage