import { api } from "../api"
import { getCustomerPdps } from "./customer.utils"
import { refreshFirebaseToken } from "../../firebase"

export const customerApi = api.injectEndpoints({
  endpoints: build => ({
    getCustomer: build.query({
      query: customerCode => ({ url: `customers/${customerCode}` }),
      transformResponse: (response, meta, arg) => response.data
    }),
    getBills: build.query({
      query: ({ customerCode, page = 1, sortParam = "" }) => ({
         url: `sales-documents?page=${page}&filters=customerNumber==${customerCode}&sorts=${sortParam}`
      }),
      transformResponse: (response, meta, arg) => response.data
    }),
    getBillPdf: build.query({
      query: ({ documentId, documentType }) => ({
        url: `sales-documents/${documentId}/document?type=${documentType}`
      }),
      transformResponse: (response, meta, arg) => response.data
    }),
    getSupplyPoints: build.query({
      async queryFn(args, { getState }) {
        try {
          const accessToken = await refreshFirebaseToken()
          const customerPods = await getCustomerPdps({ 
            pdpType: 'pod',
            customerCode: args.customerCode, 
            accessToken 
          })
          const customerPdrs = await getCustomerPdps({ 
            pdpType: 'pdr',
            customerCode: args.customerCode, 
            accessToken 
          })
          if(args.filterBy) {
            const supplyPoints = [...customerPods, ...customerPdrs]
            return { data: supplyPoints.filter(item => item[args.filterBy.field] === args.filterBy.value) }
          }
          return { data: [...customerPods, ...customerPdrs] }
        }
        catch(err) {
          return { error: { ...err } }
        }
      }
    }),
    getPdrSupplyPoints: build.query({
      async queryFn(args, { getState }) {
        try {
          const accessToken = await refreshFirebaseToken()
          const customerPdrs = await getCustomerPdps({ 
            pdpType: 'pdr',
            customerCode: args.customerCode, 
            accessToken 
          })
          return { data: customerPdrs.filter(item => item.status === "active") }
        }
        catch(err) {
          return { error: { ...err } }
        }
      }
    }),
    getSelfMeterRead: build.query({
      query: pdrNo => ({
        url: `pdr/${pdrNo}/self-meter-reading`
      }),
      transformResponse: (response, meta, arg) => response.data
    }),
    getCustomerCadastralData: build.query({
      query: customerCode => ({
        url: `customers/${customerCode}/cadastral-data`
      }),
      transformResponse: (response, meta, arg) => response.data,
      providesTags: (result, error, id) => [{ type: 'CadastralData', id }]
    })
  })
})

export const {
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useGetBillsQuery,
  useGetSupplyPointsQuery,
  useGetSelfMeterReadQuery,
  useLazyGetCustomerCadastralDataQuery,
  useGetPdrSupplyPointsQuery,
} = customerApi

export const {
  endpoints: { 
    getCustomer,
    getBills,
    getCustomerCadastralData,
  }
} = customerApi