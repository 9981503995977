import { createGlobalStyle } from "styled-components"

const ButtonStyles = createGlobalStyle`
  button {
    font-weight: 500 !important;
  }

  .ab-button {
    display: flex;
  }
`

export default ButtonStyles