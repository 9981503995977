import { createReducer } from "@reduxjs/toolkit"

import { 
  toggleUtilityModalVisibility, customerChangeSelectedTab, resetCustomerTab 
} from './customer.actions'
import { AsyncStatusEnum, CustomerTabsMap } from "./customer.utils"

const INITIAL_STATE = {
  selectedTab: CustomerTabsMap.CUSTOMER_DATA,
  fetchStatus: [null, AsyncStatusEnum.IDLE],
  submitStatus: [null, AsyncStatusEnum.IDLE],
  error: null,
  utilityModal: {
    visibility: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const customerReducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(toggleUtilityModalVisibility, (state, action) => {
      state.utilityModal.visibility = !state.utilityModal.visibility
    })
    .addCase(customerChangeSelectedTab, (state, action) => {
      state.selectedTab = action.payload
    })
    .addCase(resetCustomerTab, (state, action) => {
      state.selectedTab = INITIAL_STATE.selectedTab
    })
})

export default customerReducer