import { createGlobalStyle } from "styled-components"

const GenericStyles = createGlobalStyle`
  body {
    font-family: "Roboto", sans-serif;
  }

  .data-tab-container {
    padding: 1.5rem 1rem;
    background-color: ${({ theme }) => theme.tabContentBkg};
    border-radius: 0 0 5px 5px;
  }

  .spin-animation {
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export default GenericStyles