import styled  from "styled-components"

const PaginationStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  
  
`

export default PaginationStyle