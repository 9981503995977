import BillsListHeaderStyle from './style'
import SortableTh from './SortableTh.component'

const BillsListHeader = props => {
  return (
    <BillsListHeaderStyle>
      <tr>
        <th className="w-15">N° Bolletta</th>
        <th className="w-15">Indirizzo</th>
        <SortableTh 
          colParam="date" 
          colLabel="Data emissione"
          defaultValue  
          {...props}
        />
        <th className="w-10">Periodo</th>
        <SortableTh 
          colParam="amount" 
          colLabel="Importo"
          defaultValue  
          {...props}
        />
        <SortableTh 
          colParam="consumption" 
          colLabel="Consumo"
          defaultValue  
          {...props}
        />
        <th className="w-10">Scadenza</th>
        <th className="w-5">Bolletta</th>
        <th className="w-5">Dettaglio</th>
      </tr>
    </BillsListHeaderStyle>
  )
}

export default BillsListHeader