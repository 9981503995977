import styled from "styled-components"

const LoginPageStyle = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2.5rem 1rem;

  svg {
    max-width: 280px;
    margin-bottom: 1.5rem;
  }

  .form-control {
    min-width: 280px;
  }

  .invalid-feedback {
    color: ${({ theme }) => theme.invalidTextColor};
    font-size: .75rem;
  }
`

export default LoginPageStyle