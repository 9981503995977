import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Form, FormGroup, Label, Button, Spinner, FormText } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomerCodeSearchStyle from './style'
import { clientCodeCheck } from '../../../utils/validations'
import { withRouter } from '../../AppRouter/utils'

const CustomerCodeSearchForm = ({
  router: {
    params: {
      customerCode
    }
  },
  formTitle,
  trigger,
  isFetching: isFetchingData,
}) => {
  const { register, handleSubmit, watch } = useForm()
  const watchCustomerCode = watch("customerCode", customerCode ?? null)

  const onSubmit = values => trigger(values.customerCode)
  useEffect(() => {
    if(customerCode) {
      trigger(customerCode)
    }
  }, [trigger, customerCode])

  return (
    <CustomerCodeSearchStyle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>
            {formTitle || "Inserisci il codice cliente per visualizzare i suoi dati"}
          </Label>
          <input 
            type="text"
            placeholder="Ad esempio: D123456"
            className="form-control"
            {...register("customerCode", {
              required: true,
              value: customerCode ?? null
            })}
          />
          <FormText>
            Il codice cliente deve iniziare con la lettera D o con la lettera B, seguita da 6 numeri.
          </FormText>
        </FormGroup>
        <FormGroup className="text-end">
          <Button
            disabled={
              !watchCustomerCode ||
              clientCodeCheck(watchCustomerCode) === false
            }
          >
            {isFetchingData ? (
              <Spinner size="sm" className="me-2" />
            ) : (
              <FontAwesomeIcon icon="magnifying-glass" className="me-2" />
            )}
            Cerca
          </Button>
        </FormGroup>
      </Form>
    </CustomerCodeSearchStyle>
  )
}

export default withRouter(CustomerCodeSearchForm)