import React from 'react'
import { ThemeProvider } from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { ToastContainer } from "react-toastify"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'

import AppRouter from './components/AppRouter/AppRouter.component'
import WithSpinner from './components/WithSpinner/WithSpinner.component'
import AuthModal from './components/modals/AuthModal/AuthModal.component'
import { useCheckUserSessionQuery } from './redux/auth/auth.api'
import { themeVariables } from './styles/ThemeVariables.style'
import GlobalStyles from './styles/GlobalStyles.style'

library.add(fas)
const AppRouterWithSpinner = WithSpinner(AppRouter)

function App() {
  const { isLoading } = useCheckUserSessionQuery()

  return (
    <ThemeProvider theme={themeVariables}>
      <GlobalStyles />
      <AppRouterWithSpinner 
        showLogo
        otherClasses={['full']}
        isLoading={isLoading} 
      />
      <AuthModal />
      <ToastContainer autoClose={2000} />
    </ThemeProvider>
  )
}

export default App