import styled from "styled-components"

const CustomerSelfMeterReadsStyle = styled.div`
  padding: 1.5rem 0;

  .self-meter-read-container {
    display: flex;
    flex-wrap: wrap;
  }

  .self-meter-read {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    min-height: 200px;
    border-radius: 10px;
    position: relative;
    background-color: #fff;
    margin: 0.75rem 0.75rem 0.75rem 0px;
    min-width: calc(33.33% - .75rem);
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 16px;

    &.is-loading {
      display: inline-block;
      background: transparent;
    }

    header {
      display: flex;
      padding: 1rem;
      min-width: 100%;
      justify-content: space-between;
      background: #26658b;
      color: #fff;

      .alias {
        font-size: 1.25rem;
        font-weight: 500;
      }

      svg {
        fill: #fff;
      }
    }

    main  {
      padding: 1rem;
      width: 100%;

      .supply-point-data {
        color: #666;
        margin-bottom: 1rem;

        &.bordered {
          padding: .75rem;
          border-radius: 10px;
          box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
          background-color: transparent;
          flex-wrap: wrap;
        }

        .data-title {
          flex-basis: 100%;
          font-size: .85rem;
          font-weight: 700;
          margin-bottom: 1rem;
        }

        .self-read-data {
          display: flex;
        }

        .data-group {
          flex-basis: 50%;
          flex-grow: 1;
          padding: 0;
          color: #666;

          label {
            margin-bottom: .15rem;
            font-size: .9rem;
            display: block;
          }

          .code {
            font-weight: 700;
            font-size: .9rem;
            display: block;
          }
        }
      }
    }
  }
`

export default CustomerSelfMeterReadsStyle