import { api } from "../api"

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    getUser: build.query({
      query: userName => ({ url: `users/${userName}` }),
      transformResponse: (response, meta, arg) => response.data
    }),
  })
})

export const {
  useGetUserQuery,
  useLazyGetUserQuery
} = userApi