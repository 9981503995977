import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navbar, Container, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import HeaderStyle from './style'
import { useLogOutMutation } from '../../redux/auth/auth.api'
import { ReactComponent as Logo } from '../../assets/svg/ab-logo.svg'
import { selectDisplayName } from '../../redux/auth/auth.selectors'

const Header = () => {
  const displayName = useSelector(selectDisplayName)
  const [logout] = useLogOutMutation()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  
  return (
    <HeaderStyle>
      <Container className="px-0">
        <Navbar expand="md">
          <NavLink className="logo" to="/">
            <Logo />
          </NavLink>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ms-auto" navbar>
              <NavItem>
                <NavLink 
                  to="/customers"
                  className="nav-link"
                >
                  Cliente
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink 
                  to="/users"
                  className="nav-link"
                >
                  Utente
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/cadastral-data"
                  className="nav-link"
                >
                  Dati catastali
                </NavLink>
              </NavItem>
              <NavItem>
                <span 
                  onClick={logout}
                  className="nav-link user-logout"
                >
                  <FontAwesomeIcon icon="right-from-bracket" className="me-1" />
                  {displayName}
                </span>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </HeaderStyle>
  )
}

export default Header